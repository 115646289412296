<template>
  <div>
    <my-nav-bar
      title="特惠购提成"
      left-text="返回"
      left-arrow
      right-text="今日特惠购"
      @click-right="toPromoteSalesDetailView"
    />
    <div class="app-main-container">
      <van-sticky offset-top="1.22667rem">
        <van-cell title="日期类型" :value="typeText" is-link center @click="showTypeActions = true" />
        <van-cell title="日期" :value="listQuery.date" is-link center @click="showDatePicker = true" />
        <van-cell title="总提成" value-class="amount" :value="`￥${totalAmount}`" />
        <!-- <van-cell title="统计" :value="totalTypeText" is-link center :border="false" @click="showTotalTypeActions = true" /> -->
        <!-- <div class="tips">提示：只有按订单统计才会显示提成单价</div> -->
      </van-sticky>
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-action-sheet v-model="showTypeActions" :actions="typeActions" @select="handleSelectedTypeAction" />
      <!-- <van-action-sheet v-model="showTotalTypeActions" :actions="totalTypeActions" @select="handleSelectedTotalTypeAction" /> -->
      <van-popup v-model="showDatePicker" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @cancel="showDatePicker = false"
          @confirm="handleDatePickerConfirm"
        />
      </van-popup>
      <promote-sales-rank v-if="ranks.length > 0" class="rank-data" :ranks="ranks" />
      <van-list v-model="loading" :finished="finished" @load="handleGetCommissions">
        <div v-for="(goods, index) in list" :key="index" class="commission-goods">
          <commission-goods :goods="goods" />
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import PromoteSalesRank from '@/components/PromoteSalesRank'
import CommissionGoods from '@/components/PromoteSalesGoods/commission'
import { currentDate, toDateString } from '@/utils'
import { getRanks, getCommissions } from '@/api/mine'
export default {
  components: { myNavBar, PromoteSalesRank, CommissionGoods },
  data() {
    return {
      typeActions: [
        { name: '年', value: 'year' },
        { name: '月', value: 'month' },
        { name: '日', value: 'day' }
      ],
      // totalTypeActions: [
      //   { name: '按商品汇总', value: 1 },
      //   { name: '按金额汇总', value: 2 }
      // ],
      currentDate: new Date(),
      showTypeActions: false,
      showTotalTypeActions: false,
      showDatePicker: false,
      listQuery: {
        page: 0,
        type: 'day',
        totalType: 1,
        date: currentDate()
      },
      typeText: '日',
      // totalTypeText: '按商品汇总',
      totalAmount: '0.00',
      ranks: [],
      list: [],
      rankInit: false,
      commissionInit: false,
      showEmpty: false,
      loading: false,
      finished: false
    }
  },
  created() {
    this.handleInit()
  },
  methods: {
    handleInit() {
      this.showEmpty = false
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.rankInit = false
      this.commissionInit = false
      this.beginLoad()
      this.handleGetRanks()
      this.handleGetCommissions()
    },
    handleGetRanks() {
      const params = {
        type: this.listQuery.type,
        date: this.listQuery.date
      }
      getRanks(params).then(res => {
        this.ranks = res.data.ranks
        this.totalAmount = res.data.total_amount
        this.rankInit = true
        this.checkLoadFinished()
      })
    },
    handleGetCommissions() {
      this.listQuery.page++
      getCommissions(this.listQuery).then(res => {
        this.loading = false
        this.finished = res.data.list.length < 10
        this.list = this.list.concat(res.data.list)
        this.commissionInit = true
        this.checkLoadFinished()
      })
    },
    checkLoadFinished() {
      if (this.rankInit && this.commissionInit) {
        this.endLoad()
        if (this.ranks.length === 0 && this.list.length === 0) {
          this.showEmpty = true
        }
      }
    },
    handleSelectedTypeAction(action) {
      this.listQuery.type = action.value
      this.typeText = action.name
      this.showTypeActions = false
      this.handleInit()
    },
    handleSelectedTotalTypeAction(action) {
      this.listQuery.totalType = action.value
      this.totalTypeText = action.name
      this.showTotalTypeActions = false
      this.handleInit()
    },
    handleDatePickerConfirm(value) {
      this.listQuery.date = toDateString(value)
      this.showDatePicker = false
      this.handleInit()
    },
    toPromoteSalesDetailView() {
      this.$router.push({
        path: '/my-commission/promote-sales-detail'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color";
.tips {
  padding: 5px 15px;
  color: #989898;
}
.amount {
  color: $amountRedColor;
}
.commission-goods {
  margin-bottom: 10px;
  padding: 0 15px;
}
.commission-goods:first-child {
  margin-top: 10px;
}
.rank-data {
  margin-bottom: 10px;
}
</style>
