import request from '@/utils/request'

export function getRanks(params) {
  return request({
    method: 'get',
    url: 'mine/rank',
    params
  })
}

export function getCommissions(params) {
  return request({
    method: 'get',
    url: 'mine/commission',
    params
  })
}

export function getPromoteSalesProducts() {
  return request({
    method: 'get',
    url: 'mine/promote-sales-product'
  })
}
