var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.image
    }
  })], 1), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "quantity"
  }, [_vm._v("数量：" + _vm._s(_vm.goods.total_quantity) + _vm._s(_vm.goods.least_unit))]), _c('div', {
    staticClass: "amount"
  }, [_vm._v("提成单价：￥" + _vm._s(_vm.goods.pss_commission) + "/" + _vm._s(_vm.goods.least_unit))]), _c('div', {
    staticClass: "amount"
  }, [_vm._v("提成金额：￥" + _vm._s(_vm.goods.pss_commission_amount))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }