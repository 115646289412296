<template>
  <div>
    <van-cell-group inset title="排行榜">
      <van-cell v-for="(rank, index) in ranks" :key="index">
        <template #title>
          <div class="cell-title">
            <div class="rank-title">
              <van-image v-if="index < 3" class="rank-icon" :src="require(`@/assets/images/${imgs[index]}.png`)" />
              <span>第 {{ index + 1 }} 名</span>
            </div>
            <span class="rank-name">{{ rank.name }}({{ rank.store.name }})</span>
          </div>
        </template>
        <template>
          <div class="cell-value">
            <!-- <span class="amount-tips">(未减退货)</span> -->
            <span class="amount">提成：￥{{ rank.pss_commission_amount }}</span>
            <span v-if="rank.after_amount < 0" class="amount">退货：￥{{ rank.after_amount }}</span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  props: {
    ranks: []
  },
  data() {
    return {
      imgs: ['jinpai', 'yinpai', 'tongpai']
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color";
.cell-title {
  display: flex;
  flex-direction: column;
}
.cell-value {
  display: flex;
  flex-direction: column;
}
.amount {
  color: $amountRedColor;
}
.amount-tips {
  font-size: 12px;
}
.rank-title {
  display: flex;
  align-items: center;
  color: #969799;
}
.rank-icon {
  width: 16px;
  height: auto;
}
</style>
