var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('van-cell-group', {
    attrs: {
      "inset": "",
      "title": "排行榜"
    }
  }, _vm._l(_vm.ranks, function (rank, index) {
    return _c('van-cell', {
      key: index,
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('div', {
            staticClass: "cell-title"
          }, [_c('div', {
            staticClass: "rank-title"
          }, [index < 3 ? _c('van-image', {
            staticClass: "rank-icon",
            attrs: {
              "src": require("@/assets/images/".concat(_vm.imgs[index], ".png"))
            }
          }) : _vm._e(), _c('span', [_vm._v("第 " + _vm._s(index + 1) + " 名")])], 1), _c('span', {
            staticClass: "rank-name"
          }, [_vm._v(_vm._s(rank.name) + "(" + _vm._s(rank.store.name) + ")")])])];
        },
        proxy: true
      }], null, true)
    }, [[_c('div', {
      staticClass: "cell-value"
    }, [_c('span', {
      staticClass: "amount"
    }, [_vm._v("提成：￥" + _vm._s(rank.pss_commission_amount))]), rank.after_amount < 0 ? _c('span', {
      staticClass: "amount"
    }, [_vm._v("退货：￥" + _vm._s(rank.after_amount))]) : _vm._e()])]], 2);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }